'use strict'
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/all";
// import SmoothScroll from "./js/SmoothScroll.min";
// import ScrollSmoother from "./js/ScrollSmoother.min.js";
import editor from './editor';
import './scss/style.scss';


document.addEventListener('DOMContentLoaded', () => {
  editor()

  gsap.registerPlugin(ScrollTrigger, ScrollSmoother, ScrollToPlugin)

  let smoother = ScrollSmoother.create({
    wrapper: 'smooth-wrapper',
    content: 'smooth-content',
    smooth: 1,
    effects: true,
  });

  const links = document.querySelectorAll('.header .anchor');

  links.forEach(item => {
    item.addEventListener('click', (e) => {
      e.preventDefault();
      const href = e.target.getAttribute("href")

      smoother.scrollTo(href, true, "top top");
    })
  })

  

  const tlPromo = gsap.timeline({
    scrollTrigger: {
      trigger: '.promo',
      start: 'top top',
      end: 'bottom top',
      scrub: 1
    },
    defaults: {
      ease: "power1.inOut"
    }
  });

  tlPromo.to(".promo__title", {y: -100})
  .to(".promo__img-1", {y: -200}, "<")
  .to(".promo__img-2", {y: -150}, "<")
  .to(".promo__img-3", {y: -250}, "<")


  const tlDescr = gsap.timeline({
    scrollTrigger: {
      trigger: '.descr',
      start: 'top center',
      toggleActions: "play pause resume reverse",
    },
    defaults: {
      ease: "power1.inOut",
      duration: 1.5
    }
  })
  tlDescr.from(".descr__box:nth-child(1)", {x: "-40vw"})
    .from(".descr__box:nth-child(1) .second", {x: "-200%"}, "<")
    .from(".descr__box:nth-child(2)", {x: "40vw"}, "<")
    .from(".descr__box:nth-child(2) .second", {x: "200%"}, "<")
    .from(".descr__box:nth-child(3)", {x: "40vw"}, "<")
    .from(".descr__box:nth-child(3) .second", {x: "100%"}, "<")
    .from(".descr__box:nth-child(4)", {x: "-45vw"}, "<")


  const catTl = gsap.timeline({
    scrollTrigger: {
      trigger: ".join",
      start: "top top",
      pin: '.cat-wrapper',
      pinSpacing: false,
      endTrigger: '.steps',
      end: "bottom top",
      scrub: 1,
      // markers: true
    },
    defaults: {
      duration: 4
    }
  })
  catTl.to(".cat", { opacity: 1, y: "-10%"}, "<2")
  .to(".cat", {opacity: 0, y: "-100%", ease: "elastic.in(1.2,1)"}, "+=50%")


  const openEditorBtn = document.querySelector('.join__create');
  const closeEditorBtn = document.querySelector('.editor__delete');
  const editorDialog = document.querySelector('#editor')

  const modalAnimate = gsap.from(editorDialog, {
    duration: 0.3,
    autoAlpha: 0,
    scale: 0.7,
    onReverseComplete: () => {
      editorDialog.close();
    }
  })

  openEditorBtn.addEventListener('click', () => {
    editorDialog.showModal();
    modalAnimate.play(0)
  })
 
  closeEditorBtn.addEventListener('click', () => {
    modalAnimate.reverse()
  })





  gsap.to('.preloader', {
    duration: 0.5,
    autoAlpha: 0,
    onComplete: () => {
      gsap.set('.preloader', {display: 'none'})
    }
  })

  })
